// Define your application's custom theme.

// import the custom palettes
@import "./extra-palettes.scss";

/**
 *  Create the stand material palettes
 *  These palettes will be used by the built in angular material objects as well as any custom components
 */
$primary: mat-palette($lightGreen);
$accent: mat-palette($mat-amber);
$warn: mat-palette($mat-pink, 300, 100, 500);

/**
 *  Create any extra palettes you want to use and add them to a map in order to use them with the theme.
 *  Note that the default angular material components have no way of using these colors because they were created with only the default theme variables in mind. If you want to style a built in angular material component you should change the primary, accent, or warn colors for this theme otherwise you will have to create custom versions of the angular material components which make use of these new colors yourself.
 */
$extra-palettes: (
  extra-color-1: mat-palette($mat-purple, 500, 400, 600),
  extra-color-2: mat-palette($orange, 500, 200, A700)
);

// Create the material theme and merge it with the extra palettes
$theme4: map-merge(
  mat-light-theme(
    $primary,
    $accent,
    $warn
  ),
  $extra-palettes
);

$theme4-dark: map-merge(
  mat-dark-theme(
    $primary,
    $accent,
    $warn
  )
  ,
  $extra-palettes
);
