/*
Template Name: Material Pro Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/
/**
 * Table Of Content
 *
 * 	1. Globals
 *	2. Headers
 *	3. Navigations
 *	4. Banners
 *	5. Footers
 *	6. app
 *	7. Widgets
 *	8. Custom Templates
 */

// @import 'variable';

// @import 'spinner';
// @import 'app';
// @import 'header';
// @import 'pages';
// @import 'sidebar';
// @import 'responsive';

// @import "../styles/icons/material-design-iconic-font/css/materialdesignicons.min.css";
/* You can add global styles to this file, and also import other style files */


/* md-icon{
    font-family: 'Material Icons' !important;
} */

div.container{
    margin: 0px 40px;
}

.fill-remaining-space{
    flex: 1 1 auto;
}

form-normal-form{
    margin: 10px;
}

/* notification */
snack-bar-container.success {
    background-color: #5cb85c;
    color: #fff;
}

snack-bar-container.warn {
    background-color: #f99157;
    color: #fff;
}

snack-bar-container.notification simple-snack-bar{
    font-size: 14px !important;
  //  font-weight: bold;
}

hide{
    display: none;
}

/*mat-table*/
/* mat-header-row mat-header-cell{
    font-size: 14px;
    align-items: center;
    background-color: #66b3ff; 
}  */


/* ,
td.mat-cell  */
/* .mat-header-row, .mat-row {
    min-height: 30px;
} */

/* tr.mat-header-row, tr.mat-row {
    height: 30px;
} */

table {
    width: 100%;
}
.td1
{
vertical-align:baseline;
width:auto;
color:#0ca7db;

}
.td2
{
vertical-align: top;
width:75%;
color:#0ca7db
}
.td3
{
vertical-align: top;
align-items:baseline;
width:20%;
}
.td4
{
vertical-align: top;
width:65%;
color:blue;
// background-image: linear-gradient(to left, indigo, blue, );   -webkit-background-clip: text;
// color: transparent;
}

.listheader
{
font-size:16px;
font-weight:500;

}

// img {
// border-radius: 50%;
// }
.hr1 {
border: 0.1em solid silver;

}
.text-area
{
  width:100%;
}
tr.mat-header-row{
    height: 30px !important;
    
}

tr.mat-row{
    height: 30px !important;
}
  
th.mat-header-cell
{
  /* text-align: center !important; */
  background-color:rgb(143, 150, 156);
  color: white;
  height: 35px !important;
}

.mat-row:nth-child(even){
    background-color: #e4f0ec;
}
  
mat-footer-row mat-footer-cell{
    justify-content: center;
    font-style: italic;
}

/* filter controls */
.search-div{
    margin: 10px;
      }
      .search-form-field{
          width: 60%;
          margin-left: 0px;
          padding: 5px 5px;
          background-color: #f5f5f5;
          border-radius: 5px;
          }
          .search-form-field div.mat-form-field-underline {
              display: none;
          }
          .search-form-field div.mat-form-field-infix{
              border-top: 0px;
          }
          .search-form-field div.mat-form-field-wrapper{
            padding-bottom: 0px;
        }
        .search-form-field div.mat-form-field-suffix button{
            height: 32px;
            width: 32px;
        }

/* for dialog pop-up */
.btn-dialog-close{
    width: 45px;
    min-width: 0px !important;
    height: 40px;
    padding: 0px !important;
}

// /*for fab button css */
// .light-button {
//        //border: 2px solid blue !important;
//      // background-color: rgb(94, 89, 89) !important;      
// } 

.light-button:hover {   
   // background-color:#00A693 !important;  
    transform: scale(1.1); 
  }

// .mat-button {
//     transform: scale(1.1); 
// }
  /* for progress spinner indicator *////
  .loading-indicator {
    position: fixed;
    z-index: 999;
    height: 2em;
    width: 2em;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  
  /* Transparent Overlay */
  .loading-indicator:before {   
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;    
  }
  
.mat-dialog-container {
    border-radius: .25em .25em .4em .4em;
    padding: 0px;
    background-color: #f2f6f8;   
    font-size: 13px; 
}

/* mat-confirm-dialog (customised dialog) */
.confirm-dialog-container .mat-dialog-container {
    border-radius: .25em .25em .4em .4em;
    padding: 0px;
}
.confirm-dialog-container .content-container{
    margin: 5px 5px 15px 5px;
    color: #8f9cb5;
    display: flex;
}
.confirm-dialog-container #close-icon{
    margin-left: auto;
    order: 2;
    font-weight: bolder;
    color: red;
}
.confirm-dialog-container #close-icon:hover{
cursor: pointer;
}

.confirm-dialog-container #no-button{
height: 50px;
width: 50%;
background-color: #fc7169;
color:white;
border-radius: 0px;
}

.confirm-dialog-container #yes-button{
    height: 50px;
    width: 50%;
    background-color: #b6bece;
    color:white;
    border-radius: 0px;   
}

.confirm-dialog-container span.content-span{
    padding:  35px 16px;
    text-align: center;    
    font-size: 16px;
}

/* .custom-dialog-container .mat-dialog-container {
    transform: none;
    padding-top: 15px;
    padding-right: 15px;
    padding-left: 15px;
    padding-bottom: 15px;
} */


.example-container {
    display: flex;
    flex-direction: column;
    // max-height: 500px;
    min-width: 300px;
  }
  
  .mat-table {
    overflow: auto;
    // max-height: 500px;
  }

.mat-header-cell
{
  background-color:rgb(143, 150, 156);
  color: white;

}

.mat-header-row, .mat-row  {
    min-height: 35px;
} 
  .mobile-label {
    display: none; 
  }
  
  @media(max-width: 600px) {
    .mobile-label {
      width: 90px;
      display: inline-block;
          font-weight: bold;
    }
  
    // .mat-header-row  {
    //   display: none;
    // }
    .mat-row { 
      flex-direction: column;
      align-items: start;
      padding: 12px 10px;
    }
    .mat-cell:first-of-type, mat-header-cell:first-of-type, mat-footer-cell:first-of-type {
        padding-left: 0px;
    }


  }
//   .mat-cell,
//   .mat-header-cell {
//     flex: 0 0 11%;
//     // display: inline-flex;
// //   min-width: 100%;
//   }

// .cdk-overlay-container .cdk-overlay-pane .ddlpanelclass {
//   margin-top: 8%;
// }
