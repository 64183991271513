/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


// /*Theme*/
// @import "theme/blue.scss";
// @import "theme/red.scss";
// @import "src/theme/variables.scss";


//  @import '~devextreme/dist/css/dx.common.css';
//  @import '~devextreme/dist/css/dx.dark.css';
//  @import '~devextreme/dist/css/dx.light.css';
 


@import '../src/assets/styles/app.scss';
@import '../src/assets/styles/pages.scss';
@import '../src/assets/styles/style.scss';
@import '../src/assets/styles/responsive.scss';
// @import '../src/assets/styles/variable.scss';
@import '../src/assets/styles/sidebar.scss';
@import '../src/assets/styles/header.scss';
//@import '../src/assets/styles/app.scss';

// Include material themeing functions.
@import "@angular/material/theming";

// Include non-theme styles for core.
@include mat-core();


// Import the custom themes.
@import "../src/theme/theme1.scss";
@import "../src/theme/theme2.scss";
@import "../src/theme/theme3.scss";
@import "../src/theme/theme4.scss";
@import "../src/theme/theme5.scss";
@import "../src/theme/theme6.scss";
@import "../src/theme/theme7.scss";
@import "../src/theme/theme8.scss";

.theme-1.light {
    // Call the main material theme mixin to create all the default theming styles.
    @include angular-material-theme($theme1);
    // Call the custom component mixin to create all the custom component theming styles
    //@include custom-component-theming($theme1);
  }
  
  .theme-1.dark {
    
    // Call the main material theme mixin to create all the default theming styles.    
    @include angular-material-theme($theme1-dark);

    // Call the custom component mixin to create all the custom component theming styles
    //@include custom-component-theming($theme1-dark);
  }
  
  .theme-2.light {
    // Call the main material theme mixin to create all the default theming styles.
    @include angular-material-theme($theme2);
    // Call the custom component mixin to create all the custom component theming styles
    //@include custom-component-theming($theme2);
  }
  
  .theme-2.dark {
    // Call the main material theme mixin to create all the default theming styles.
    @include angular-material-theme($theme2-dark);
    // Call the custom component mixin to create all the custom component theming styles
    //@include custom-component-theming($theme2-dark);
  }

  .theme-3.light {
    // Call the main material theme mixin to create all the default theming styles.
    @include angular-material-theme($theme3);
    // Call the custom component mixin to create all the custom component theming styles
    //@include custom-component-theming($theme2);
  }
  
  .theme-3.dark {
    // Call the main material theme mixin to create all the default theming styles.
    @include angular-material-theme($theme3-dark);
    // Call the custom component mixin to create all the custom component theming styles
    //@include custom-component-theming($theme2-dark);
  }
  .theme-4.light {
    // Call the main material theme mixin to create all the default theming styles.
    @include angular-material-theme($theme4);
    // Call the custom component mixin to create all the custom component theming styles
    //@include custom-component-theming($theme2);
  }
  
  .theme-4.dark {
    // Call the main material theme mixin to create all the default theming styles.
    @include angular-material-theme($theme4-dark);
    // Call the custom component mixin to create all the custom component theming styles
    //@include custom-component-theming($theme2-dark);
  }

  .theme-5.light {
    // Call the main material theme mixin to create all the default theming styles.
    @include angular-material-theme($theme5);
    // Call the custom component mixin to create all the custom component theming styles
    //@include custom-component-theming($theme2);
  }
  
  .theme-5.dark {
    // Call the main material theme mixin to create all the default theming styles.
    @include angular-material-theme($theme5-dark);
    // Call the custom component mixin to create all the custom component theming styles
    //@include custom-component-theming($theme2-dark);
  }

  .theme-6.light {
    // Call the main material theme mixin to create all the default theming styles.
    @include angular-material-theme($theme6);
    // Call the custom component mixin to create all the custom component theming styles
    //@include custom-component-theming($theme2);
  }
  
  .theme-6.dark {
    // Call the main material theme mixin to create all the default theming styles.
    @include angular-material-theme($theme6-dark);
    // Call the custom component mixin to create all the custom component theming styles
    //@include custom-component-theming($theme2-dark);
  }

  .theme-7.light {
    // Call the main material theme mixin to create all the default theming styles.
    @include angular-material-theme($theme7);
    // Call the custom component mixin to create all the custom component theming styles
    //@include custom-component-theming($theme2);
  }
  
  .theme-7.dark {
    // Call the main material theme mixin to create all the default theming styles.
    @include angular-material-theme($theme7-dark);
    // Call the custom component mixin to create all the custom component theming styles
    //@include custom-component-theming($theme2-dark);
  }

  .theme-8.light {
    // Call the main material theme mixin to create all the default theming styles.
    @include angular-material-theme($theme8);
    // Call the custom component mixin to create all the custom component theming styles
    //@include custom-component-theming($theme2);
  }
  
  .theme-8.dark {
    // Call the main material theme mixin to create all the default theming styles.
    @include angular-material-theme($theme8-dark);
    // Call the custom component mixin to create all the custom component theming styles
    //@include custom-component-theming($theme2-dark);
  }